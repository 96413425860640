import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "core-js/modules/es.array.push.js";
import { projectPlanInfo, activationRate, activeRate } from '@/api/wxproject';
import ActivationStackedChart from '@/components/ActivationStackedChart';
import ActivationStackedChart1 from '@/components/ActivationStackedChart1';
import ActivationPieChart from '@/components/ActivationPieChart';
import moment from 'moment';
export default {
  components: {
    [_Cell.name]: _Cell,
    ActivationStackedChart,
    ActivationStackedChart1,
    ActivationPieChart
  },
  data() {
    return {
      datetype: '',
      projectCode: '',
      month: '',
      monthdata: '',
      projectDate: [],
      activationdate: [],
      activationchatdata: [],
      activationdateTitle: ['计划终端任务数', '已激活终端任务数'],
      activationchatdatalast: [],
      chartData1: [],
      chartData2: [],
      activedate: [],
      activechatdata: [],
      activedateTitle: ['周应活跃', '周活跃获取', '周活跃合格'],
      activechatdatalast: []
    };
  },
  mounted() {
    if (this.$route.query.type === 'now') {
      document.title = '实时动态';
    } else {
      document.title = '历史记录';
    }
    console.log(this.$route.query.projectCode, this.$route.query.month);
    this.projectCode = this.$route.query.projectCode;
    this.month = this.$route.query.month;
    this.datetype = this.$route.query.type;
    projectPlanInfo({
      projectCode: this.$route.query.projectCode,
      month: this.$route.query.month
    }).then(res => {
      this.projectDate = res.data.data;
      console.log(res.data.data.month);
      if (res.data.data.month.substr(res.data.data.month.length - 2, 1) == '0') {
        this.monthdata = res.data.data.month.substr(res.data.data.month.length - 1, 1);
      } else {
        this.monthdata = res.data.data.month.substr(res.data.data.month.length - 2, 2);
        // this.monthdata = res.data.data.month.split('-')[1]
      }
    });

    activationRate({
      projectCode: this.$route.query.projectCode,
      month: this.$route.query.month
    }).then(res1 => {
      this.activationdate = res1.data.data;
      this.activationchatdata = res1.data.data.hisWeekActivationRateList;
      this.getdatachat1();
      this.getChartData1();
    });
    activeRate({
      projectCode: this.$route.query.projectCode,
      month: this.$route.query.month,
      type: this.$route.query.type
    }).then(res2 => {
      this.activedate = res2.data.data;
      this.activechatdata = res2.data.data.attWeek;
      this.getdatachat2();
      this.getChartData2();
      this.$nextTick(() => {
        var _this$$refs$pieChart;
        (_this$$refs$pieChart = this.$refs.pieChart) === null || _this$$refs$pieChart === void 0 ? void 0 : _this$$refs$pieChart.initChart();
      });
    });
  },
  methods: {
    getdatachat1() {
      this.activationchatdata.map(res => {
        this.activationdateTitle.map(res1 => {
          let data = 0;
          if (res1 === '计划终端任务数') {
            data = res.weekTerminalPlanNum;
          } else {
            data = res.newWeekTerminalPlanNum;
          }
          this.activationchatdatalast.push({
            name: res1,
            week: `第${res.weekOfMonth}周 \n (${res.startDate.slice(5)} - ${res.endDate.slice(5)})`,
            cnt: data,
            index: res.weekOfMonth
          });
        });
      });
      if (this.activationchatdatalast.length > 8) {
        this.activationchatdatalast.unshift({
          name: '计划终端任务数',
          week: '',
          cnt: null,
          index: 0
        }, {
          name: '已激活终端任务数',
          week: '',
          cnt: null,
          index: 0
        });
        this.activationchatdatalast.push({
          name: '计划终端任务数',
          week: '',
          cnt: null,
          index: this.activationchatdatalast.length / 2
        }, {
          name: '已激活终端任务数',
          week: '',
          cnt: null,
          index: this.activationchatdatalast.length / 2
        });
      }
      console.log(this.activationchatdatalast);
    },
    getdatachat2() {
      this.activechatdata.map(res => {
        this.activedateTitle.map(res1 => {
          let data = 0;
          // if(res1 === '周应活跃'){
          //   data = res.attPlanWeek - res.attWeek
          // }else if(res1 === '周活跃获取'){
          //   data = res.attWeek - res.attPassWeek
          // }else{
          //   data = res.attPassWeek
          // }
          if (res1 === '周应活跃') {
            data = res.attPlanWeek;
          } else if (res1 === '周活跃获取') {
            data = res.attWeek;
          } else {
            data = res.attPassWeek;
          }
          this.activechatdatalast.push({
            name: res1,
            week: `第${res.weekNo}周  \n (${res.dates})`,
            cnt: data,
            index: res.weekNo
          });
        });
      });
      if (this.activechatdatalast.length > 12) {
        this.activechatdatalast.unshift({
          name: '周应活跃',
          week: '',
          cnt: null,
          index: 0
        }, {
          name: '周活跃获取',
          week: '',
          cnt: null,
          index: 0
        }, {
          name: '周活跃合格',
          week: '',
          cnt: null,
          index: 0
        });
        this.activechatdatalast.push({
          name: '周应活跃',
          week: '',
          cnt: null,
          index: this.activechatdatalast.length / 3
        }, {
          name: '周活跃获取',
          week: '',
          cnt: null,
          index: this.activechatdatalast.length / 3
        }, {
          name: '周活跃合格',
          week: '',
          cnt: null,
          index: this.activechatdatalast.length / 3
        });
      }
    },
    goActivation() {
      this.$router.push({
        path: '/ActivationDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type
          // month:'2022-04',
        }
      });
    },

    goActive(dateType = '周') {
      this.$router.push({
        path: '/ActiveDetail',
        query: {
          projectCode: this.$route.query.projectCode,
          month: this.$route.query.month,
          type: this.$route.query.type,
          dateType,
          date: dateType === '日' ? moment().format('YYYY-MM-DD') : undefined
        }
      });
    },
    goTerminal() {
      this.$router.push({
        path: '/Terminal',
        query: {
          projectCode: this.projectCode,
          month: this.month,
          type: this.datetype
        }
      });
    },
    getChartData1() {
      this.chartData1 = [];
      this.chartData1 = this.activationchatdatalast;
      this.$refs.chart1.initChart();
    },
    getChartData2() {
      this.chartData2 = [];
      this.chartData2 = this.activechatdatalast;
      this.$refs.chart2.initChart();
    }
  }
};