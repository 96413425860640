import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'ActivationPieChart',
  props: ['data', 'id'],
  data() {
    return {
      chart: null,
      colors: ['rgba(237,237,237,0.85)', '#7585A2', '#73DEB3'],
      originDates: []
    };
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 0);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let width = this.$refs.chart.offsetWidth;
        let height = this.$refs.chart.offsetHeight;
        this.chart = new F2.Chart({
          width,
          height,
          id: this.id,
          pixelRatio: window.devicePixelRatio
        });
        this.chart.tooltip({}).axis(false).legend({
          align: 'center',
          marker: {
            symbol: 'square'
          },
          // clickable: false,
          position: 'bottom'
        }).source(this.data, {}).coord('polar', {
          transposed: true,
          radius: 0.8
        });
        // 添加饼图文本
        // this.chart.pieLabel({
        //   sidePadding: 40,
        //   label1: function label1(data, color) {
        //     return { text: data.name, fill: color, fontWeight: 'bold' };
        //   },
        //   label2: function label2(data, color) {
        //     return { text: data.num, fill: color };
        //   },
        // });
        //载入数据源
        this.chart.interval().position('a*num').color('name', ['#7DCBE9', '#ccc']).adjust('stack').style({
          lineWidth: 1,
          stroke: '#fff',
          lineJoin: 'round',
          lineCap: 'round'
        }).animate({
          appear: {
            duration: 1200,
            easing: 'bounceOut'
          }
        });
        this.chart.render();
      });
    }
  }
};