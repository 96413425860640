import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'ActivationRateChart',
  props: ['data', 'id'],
  data() {
    return {
      chart: null,
      colors: ['rgba(237,237,237,0.85)', '#73DEB3']
    };
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 0);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let width = this.$refs.chart.offsetWidth;
        let height = this.$refs.chart.offsetHeight;
        let datelist = this.data;
        console.log(this.data);
        this.chart = new F2.Chart({
          width,
          height,
          id: this.id,
          pixelRatio: window.devicePixelRatio
        });
        this.chart.tooltip(false);
        //载入数据源
        if (this.data.length > 10) {
          this.chart.source(this.data, {
            index: {
              type: 'linear',
              tickCount: 5,
              mix: 0,
              max: 4,
              tickInterval: 1
            }
          });
          this.chart.interaction('pan');
          this.chart.legend({
            align: 'center',
            marker: {
              symbol: 'square'
            },
            clickable: false
          });
          this.chart.axis('index', {
            label(text) {
              // console.log(text)
              if (text !== 0) {
                return {
                  fontSize: 12,
                  text: datelist[text * 2].week // 在这里将横坐标显示为属性为lx的内容
                };
              } else {
                return {
                  fontSize: 12,
                  text: ''
                };
              }
            }
          });
          this.data.map((res, index) => {
            // console.log(index % 2);
            if (index % 2 === 0) {
              this.chart.guide().text({
                position: [index / 2, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#CCCCCC'
                },
                offsetY: -8,
                offsetX: -18,
                limitInPlot: true
              });
            } else {
              this.chart.guide().text({
                position: [(index - 1) / 2, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#70DBB0'
                },
                offsetY: -8,
                offsetX: 18,
                limitInPlot: true
              });
            }
          });
          this.chart.scrollBar({
            mode: 'x',
            xStyle: {
              backgroundColor: '#e8e8e8',
              fillerColor: '#ccc',
              offsetY: -8
            }
          });
          this.chart.interval().position('index*cnt').color('name', this.colors).adjust({
            type: 'dodge',
            marginRatio: 0 // 设置分组间柱子的间距
          }).size(15);
          // Step 4: 渲染图表
          this.chart.render();
        } else {
          this.chart.source(this.data);
          this.chart.legend({
            align: 'center',
            marker: {
              symbol: 'square'
            },
            clickable: false
          });
          // this.chart.tooltip({
          //   triggerOn: ['touchstart'],
          // });
          this.data.map((res, index) => {
            // console.log(index % 3);
            if (index % 2 === 0) {
              this.chart.guide().text({
                position: [index / 2, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#CCCCCC'
                },
                offsetY: -8,
                offsetX: -18,
                limitInPlot: true
              });
            } else {
              this.chart.guide().text({
                position: [(index - 1) / 2, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#70DBB0'
                },
                offsetY: -8,
                offsetX: 18,
                limitInPlot: true
              });
            }
          });
          this.chart.interval().position('week*cnt').color('name', this.colors).adjust({
            type: 'dodge',
            marginRatio: 0 // 设置分组间柱子的间距
          }).size(15);
          // Step 4: 渲染图表
          this.chart.render();
        }
      });
    }
  }
};