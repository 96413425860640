import "core-js/modules/es.array.push.js";
import F2 from '@antv/f2/lib/index-all';
export default {
  name: 'ActivationRateChart',
  props: ['data', 'id'],
  data() {
    return {
      chart: null,
      colors: ['rgba(237,237,237,0.85)', '#7585A2', '#73DEB3'],
      originDates: []
    };
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 0);
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        let width = this.$refs.chart.offsetWidth;
        let height = this.$refs.chart.offsetHeight;
        let datelist = this.data;
        let listtick = [];
        this.data.map(res => {
          if (res.name === '周应活跃') {
            listtick.push(res.index);
          }
        });
        this.chart = new F2.Chart({
          width,
          height,
          id: this.id,
          pixelRatio: window.devicePixelRatio
        });
        this.chart.tooltip(false);
        this.chart.legend({
          align: 'center',
          marker: {
            symbol: 'square'
          },
          clickable: false
        });
        //载入数据源
        if (this.data.length > 15) {
          this.chart.source(this.data, {
            index: {
              type: 'linear',
              tickCount: 5,
              mix: 0,
              max: 4,
              tickInterval: 1
            }
          });
          this.chart.interaction('pan');
          this.chart.axis('index', {
            label(text) {
              // console.log(text)
              if (text !== 0) {
                return {
                  fontSize: 12,
                  text: datelist[text * 3].week // 在这里将横坐标显示为属性为lx的内容
                };
              } else {
                return {
                  fontSize: 12,
                  text: ''
                };
              }
            }
          });
          this.data.map((res, index) => {
            if (index % 3 === 0) {
              this.chart.guide().text({
                position: [index / 3, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#CCCCCC'
                },
                offsetY: -8,
                offsetX: -18,
                limitInPlot: true
              });
            } else if (index % 3 === 1) {
              this.chart.guide().text({
                position: [res.index, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#7585A2'
                },
                offsetY: -8,
                offsetX: 0,
                limitInPlot: true
              });
            } else if (index % 3 === 2) {
              this.chart.guide().text({
                position: [(index - 2) / 3, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#70DBB0'
                },
                offsetY: -8,
                offsetX: 18,
                limitInPlot: true
              });
            }
          });
          this.chart.scrollBar({
            mode: 'x',
            xStyle: {
              backgroundColor: '#e8e8e8',
              fillerColor: '#ccc',
              offsetY: -8
            }
          });
          this.chart.interval().position('index*cnt').color('name', this.colors).adjust({
            type: 'dodge',
            marginRatio: 0 // 设置分组间柱子的间距
          }).size(10);
          // Step 4: 渲染图表
          this.chart.render();
        } else {
          this.chart.source(this.data);
          this.data.map((res, index) => {
            if (index % 3 === 0) {
              this.chart.guide().text({
                position: [index / 3, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#CCCCCC'
                },
                offsetY: -8,
                offsetX: -18,
                limitInPlot: true
              });
            } else if (index % 3 === 1) {
              this.chart.guide().text({
                position: [res.week, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#7585A2'
                },
                offsetY: -8,
                offsetX: 0,
                limitInPlot: true
              });
            } else if (index % 3 === 2) {
              this.chart.guide().text({
                position: [(index - 2) / 3, res.cnt],
                content: res.cnt,
                style: {
                  fill: '#70DBB0'
                },
                offsetY: -8,
                offsetX: 18,
                limitInPlot: true
              });
            }
          });
          this.chart.interval().position('week*cnt').color('name', this.colors).adjust({
            type: 'dodge',
            marginRatio: 0 // 设置分组间柱子的间距
          }).size(10);
          // Step 4: 渲染图表
          this.chart.render();
        }
      });
    }
  }
};